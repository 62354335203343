import axios from "axios";
import { dataServerAddress } from "../configs/env";
import { adminUserId } from "../secrets";
import {
  newCollectionProps,
  collectionProps,
  userProps,
  newSourceProps,
  sourceProps,
  collectionsApiResponseProps,
  sourcesApiResponseProps
} from "../components/data-types";

export const submitCollection = async (
  collection: newCollectionProps,
  user: userProps,
  callback: { (): void },
): Promise<void> => {
  const fullPath = `${dataServerAddress}/collections/create/`;
  const reqBody = { //field names for oasis-data must be in snake case
    
    session: {
      user_id: user.userId,
      id_token: user.idToken,
      admin_id: adminUserId,
      group_name: "oasis-users",
    },
    
    new_collection_name: collection.name,
  };

  axios
    .post(fullPath, reqBody)
    .then((response) => {
      const resBody = response.data;
      if (resBody.succeeded) {
        return callback();
      } else {
        return callback();
      }
    })
    .catch((errors) => {
      return console.log(errors);
    });
};

export const getAndSetUserCollections = (
  user: userProps,
  setCollections: { (collections: collectionProps[]): void},
): Promise<void> => {
  const fullPath = `${dataServerAddress}/collections/fetch/`;

  const reqBody = { //field names for oasis-data must be in snake case
    user_id: user.userId,
    id_token: user.idToken,
    admin_id: adminUserId,
    group_name: "oasis-users",
  };

  const getAndSet = async () => {
    await axios
      .post(fullPath, reqBody)
      .then((response) => {
        if (response && response.data) {
          const body = response.data;

          const userCollections: collectionProps[] = []
          
          body.data.forEach((element: collectionsApiResponseProps) => {
            
            const collection: collectionProps = {
              name: element.name,
              info: element.info,
              collectionId: element.collection_id,
              createdAt: element.created_at,
              createdBy: element.created_by,
              updatedAt: element.updated_at,
              updatedBy: element.updated_by,
            };
            
            userCollections.push(collection);
        });
        
        return setCollections(userCollections);
        }
      })
      .catch((errors) => {
        return console.error(errors);
      });
  };

  return getAndSet();
};

export const getAndSetCollectionSources = (
  user: userProps,
  collectionId: Number,
  setCollectionSources: { (sources: sourceProps[]): void},
): Promise<void> => {
  const fullPath = `${dataServerAddress}/sources/fetch/`;

  const reqBody = { //field names for oasis-data must be in camel case
    
    session: {user_id: user.userId,
              id_token: user.idToken,
              admin_id: adminUserId,
              group_name: "oasis-users"},
    
    collection_id: collectionId

  };

  console.log(reqBody)

  const getAndSet = async () => {
    await axios
      .post(fullPath, reqBody)
      .then((response) => {
        if (response && response.data) {
          const body = response.data;
          console.log(body.data)
          
          const collectionSources: sourceProps[] = []
          
          body.data.forEach((element: sourcesApiResponseProps) => {
            const source: sourceProps = {
              collectionId: element.collection_id,
              name: element.name,
              info: element.info,
              sourceId: element.source_id,
              createdAt: element.created_at,
              createdBy: element.created_by,
              updatedAt: element.updated_at,
              updatedBy: element.updated_by
            };

            collectionSources.push(source)
          });

          return setCollectionSources(collectionSources);
        }
      })
      .catch((errors) => {
        return console.error(errors);
      });
  };

  return getAndSet();
};

export const submitSource = async (
  source: newSourceProps,
  user: userProps,
  callback: { (): void },
): Promise<void> => {
  const fullPath = `${dataServerAddress}/sources/create/`;
  const reqBody = { //field names for oasis-data must be in snake case
    
    session: {
      user_id: user.userId,
      id_token: user.idToken,
      admin_id: adminUserId,
      group_name: "oasis-users",
    },
    
    new_source_name: source.name,
  };

  axios
    .post(fullPath, reqBody)
    .then((response) => {
      const resBody = response.data;
      if (resBody.succeeded) {
        return callback();
      } else {
        return callback();
      }
    })
    .catch((errors) => {
      return console.log(errors);
    });
};