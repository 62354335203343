import axios from "axios";
import { setCookie } from "typescript-cookie";
import { authServerAddress } from "../configs/env";
import { adminUserId } from "../secrets";

export async function loginUser(
  formData,
  setMessage,
  setUser,
  setStatus,
  setValidated,
) {
  const fullPath = `${authServerAddress}/user/login/password/`;
  const userReqBody = {
    email: formData.email,
    password: formData.password,
    admin_user_id: adminUserId,
    group_name: "oasis-users",
    session: true,
  };
  axios
    .post(fullPath, userReqBody)
    .then((response) => {
      console.log(response)
      const body = response.data;
      if (body.succeeded) {
        const userData = {
          userId: body.data.user_id,
          idToken: body.data.id_token,
        };
        console.log(`received userData: ${userData}`);
        setValidated(true);
        setUser(userData);
        setMessage(body.message);
        setStatus("success");
        return;
      } else {
        setValidated(false);
        setMessage(body.message + ". Please try again");
        setStatus("danger");
        return;
      }
    })
    .catch((error) => {
      console.log(error);
      setValidated(false);
      setMessage(
        error.response.data.message
          ? error.response.data.message
          : "Something went wrong.",
      );
      setStatus("danger");
      return;
    });
}

export async function registerUser(formData, setMessage, setStatus) {
  const fullPath = `${authServerAddress}/user/create_account/`;
  const unformattedBody = {
    email: formData.email,
    password: formData.password,
    admin_user_id: adminUserId,
    group_name: "oasis-users",
  };
  if (formData.password !== formData.repeatPassword) {
    setMessage("Passwords must match");
  } else {
    axios
      .post(fullPath, unformattedBody)
      .then((response) => {
        const body = response.data;
        console.log(`body = ${body}`);
        if (body.succeeded) {
          setMessage(
            "User registered successfully. You should have received an email to verify the account.",
          );
          setStatus("success");
          return;
        } else {
          setMessage(body.message);
          setStatus("danger");
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        setMessage("Something went wrong.");
        setStatus("danger");
        return;
      });
  }
}
