import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { EmailInputProps } from "../types";

const EmailInput = ({
  style,
  id,
  label,
  fieldName,
  value,
  required,
  placeholder,
  setFieldValue,
  colWidth,
  readOnly,
  invalidMessage,
}: EmailInputProps) => {
  return (
    <Form.Group
      className="form-group"
      as={Col}
      xl={colWidth}
      style={
        style
          ? style
          : {
              marginBottom: "0.75em",
            }
      }
    >
      <Form.Label htmlFor={id}>{label + ":"}</Form.Label>
      <Form.Control
        id={id}
        type="email"
        placeholder={placeholder ? placeholder : label}
        value={value}
        onChange={(e) => {
          setFieldValue(fieldName, e.target.value);
        }}
        disabled={readOnly === true}
        required={required}
      />
      <Form.Control.Feedback type="invalid">
        <div className="small-font">{invalidMessage}</div>
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default EmailInput;
