import { useState, useEffect } from "react";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { collectionProps, newCollectionProps, sourceProps, newSourceProps } from "../data-types";
import { CollectionsManagerProps } from "../component-types";
import CollectionsGrid from "./collections-grid";
import SourcesGrid from "./sources-grid";
import AddCollectionForm from "../forms/add-collection";
import AddSourceForm from "../forms/add-source";
import { getAndSetUserCollections } from "../../controllers/data";
import { useUserContext } from "../../contexts/user-context";

function CollectionsManager({
  label,
  userCollections,
  setUserCollections
}: CollectionsManagerProps) {
  const [addingCollection, setAddingCollection] = useState<boolean>(false);
  const [addingSource, setAddingSource] = useState<boolean>(false);

  const [newCollection, setNewCollection] = useState<newCollectionProps>({
    name: "",
    info: "",
  });

  const [newSource, setNewSource] = useState<newSourceProps>({
    collectionId: -1,
    name: "",
    info: "",
  });

  const [selectedCollection, setSelectedCollection] = useState<collectionProps | null>(null)

  const { user } = useUserContext();

  useEffect(() => {
    getAndSetUserCollections(user, setUserCollections);
  }, [addingCollection]);

  const setFieldValue = (keyOrObject: any, value: any): void => {
    if (typeof keyOrObject === "object") {
      setNewCollection((existingValues) => ({
        ...existingValues,
        ...keyOrObject,
      }));
    } else {
      setNewCollection((existingValues) => ({
        ...existingValues,
        [keyOrObject]: value,
      }));
    }
  };

  const setSourceFieldValue = (keyOrObject: any, value: any): void => {
    if (typeof keyOrObject === "object") {
      setNewSource((existingValues) => ({
        ...existingValues,
        ...keyOrObject,
      }));
    } else {
      setNewSource((existingValues) => ({
        ...existingValues,
        [keyOrObject]: value,
      }));
    }
  };

  const toggleAddingCollection = () => {
    return setAddingCollection(!addingCollection);
  };
  const toggleAddingSource = () => {
    return setAddingSource(!addingSource);
  };

  return (
    <div className="CollectionsManager__Page">
      {addingCollection && (
        <div className="AddCollection__Form">
          <AddCollectionForm
            newCollection={newCollection}
            setFieldValue={setFieldValue}
            toggleAddingCollection={toggleAddingCollection}
          />
        </div>
      )}
      {!addingCollection && (
        <div className="CollectionsManager__Widget">
          <Row>
            <Col xl={12}>
              <h2>Welcome to the Oasis Dashboard!</h2>
            </Col>
          </Row>

          {!selectedCollection && (
            <div>
              <Stack direction="horizontal" gap={3} border-radius="10px">
                <div className="lgHeader" style={{ fontSize: '24px' }}>
                  {label}
                </div>

                <div className="ms-auto">
                  <div className="medium-padding" />
                  <Button variant="success" onClick={() => toggleAddingCollection()}>
                    Add New Collection
                  </Button>
                </div>

              </Stack>

              <CollectionsGrid
                userCollections={userCollections}
                setSelectedCollection={setSelectedCollection}
              />

            </div>

          )
          }

          {selectedCollection && !addingSource && (
            <div>
            <Stack direction="horizontal" gap={3} border-radius="10px">
              <div className="lgHeader" style={{ fontSize: '24px' }}>
                {selectedCollection.name}
              </div>

              <div className="ms-auto">
                <div className="medium-padding" />
                <Button variant="success" onClick={() => toggleAddingSource()}>
                  Add New Source
                </Button>
              </div>

            </Stack>

            <SourcesGrid
              userCollection={selectedCollection}
              setSelectedCollection={setSelectedCollection}
            />

          </div> //component for showing collections sources
          )}
          {selectedCollection && addingSource && (
            <div className="AddCollection__Form">
              <AddSourceForm
                newSource={newSource}
                setFieldValue={setSourceFieldValue}
                toggleAddingSource={toggleAddingSource}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CollectionsManager;
