import { createContext, useContext, useState } from "react";

const emptyUser = {
  userId: "",
  idToken: "",
};

const UserContext = createContext({
  user: emptyUser,
  setUser: (newUserData) => {},
});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(emptyUser);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
