import React, { useMemo } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../images/oasis-logo.png";
import { useNavigate } from "react-router-dom";
import { AppNavBarProps } from "../component-types";

function AppNavBar({ refreshToken }: AppNavBarProps) {
  const navigate = useNavigate();
  const navBar = useMemo(() => {
    if (refreshToken) {
      return (
        <Navbar
          style={{
            fontSize: "18px",
          }}
          bg="light"
          expand="lg"
        >
          <Navbar.Brand
            style={{
              marginLeft: "1em",
            }}
            onClick={() => navigate("/")}
          >
            <img
              alt=""
              src={logo}
              //For some reason these sizes aren't affecting the logo size
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{" "}
            Oasis-X
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </Navbar>
      );
    } else {
      return (
        <Navbar
          style={{
            fontSize: "18px",
          }}
          bg="light"
          expand="lg"
        >
          <Navbar.Brand
            href="#home"
            style={{
              marginLeft: "1em",
            }}
            onClick={() => navigate("/")}
          >
            <img
              alt=""
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{" "}
            O-X
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          {/*<Navbar.Collapse id='basic-navbar-nav'>
              <Nav className='me-auto'>
                <Nav.Link
                  active={window.location.href.endsWith('login')}
                  href='login'>
                  Login
                </Nav.Link>
                <Nav.Link
                  active={window.location.href.endsWith('register')}
                  href='register'>
                  Register
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>*/}
        </Navbar>
      );
    }
  }, [refreshToken]);

  return navBar;
}

export default AppNavBar;
