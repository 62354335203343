import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { SingleCheckboxProps } from "../types";

function SingleCheckbox({
  id,
  label,
  toggle,
  style,
  checked,
}: SingleCheckboxProps) {
  return (
    <Form.Check
      style={style ? style : {}}
      className="small-font"
      type="checkbox"
      id={id}
      isValid={false}
      isInvalid={false}
    >
      <Form.Check.Input
        type={"checkbox"}
        checked={checked}
        onChange={() => toggle()}
      />
      <Form.Check.Label
        style={{
          verticalAlign: "top",
          color: "black",
        }}
      >
        {label}
      </Form.Check.Label>
    </Form.Check>
  );
}

export default SingleCheckbox;
