import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextInput from "../inputs/text";
import Button from "react-bootstrap/Button";
import { submitSource } from "../../controllers/data";
import { SourceFormProps } from "../component-types";
import { useUserContext } from "../../contexts/user-context";

function AddSourceForm({
  newSource,
  setFieldValue,
  toggleAddingSource,
}: SourceFormProps) {
  const { user } = useUserContext();

  const submit = () => {
    return submitSource(newSource, user, toggleAddingSource);
  };

  return (
    <div className="SourceForm">
      <Row className="align-items-start">
        <h2> New Source </h2>
      </Row>
      <Row>
        <TextInput
          id="task-name-input"
          label="Name"
          fieldName="name"
          value={newSource.name}
          setFieldValue={setFieldValue}
          colWidth={6}
        />
      </Row>
      <div className="padding" /><Row>
        <TextInput
          id="task-info-input"
          label="Info"
          fieldName="info"
          value={newSource.info}
          setFieldValue={setFieldValue}
          colWidth={12}
        />
      </Row>
      <div className="padding" />
      <Row>
        <Col xl={3}>
          <Button variant="secondary" onClick={() => toggleAddingSource()}>
            Cancel
          </Button>
        </Col>
        <Col xl={6} />
        <Col xl={3}>
          <Button variant="success" onClick={() => submit()}>
            Add
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default AddSourceForm;
